// components/CookiesPopup.tsx
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const CookiesPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-white shadow-lg p-4 rounded-md z-50">
      <div className="flex justify-between items-center">
        <p className="text-sm text-gray-800">
          We use cookies to improve your experience on our site. By using our site, you consent to cookies.
        </p>
        <button onClick={handleClose} className="text-gray-500 hover:text-gray-800"/>
      </div>
      <div className="flex justify-end mt-2">
        <button
          onClick={handleAccept}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookiesPopup;
