// pages/index.tsx
import { FC, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { NextPage } from 'next';

import Navbar from '@components/navbar';
import Footer from '@components/footer';

import CookiesPopup from '@components/cookies-popup';

const Home: NextPage = () => {
  const [sliderValue, setSliderValue] = useState(50); // 0-100 range

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(parseFloat(event.target.value));
  };

  const sliderPosition = `${sliderValue}%`;

  return (
      <div className="font-sans font-medium">
        <Navbar />
      
        {/* Main Container */}
        <main className="bg-black text-white min-h-screen flex flex-col items-center px-6 pt-20">
          {/* Hero Section */}
          <main className="relative overflow-hidden bg-gray-900 text-white h-screen flex flex-col w-full rounded-2xl shadow-lg p-8 mb-2">
            <div className="absolute inset-0 ">
              <video
                className="w-full h-full object-cover"
                autoPlay
                muted
                loop
                playsInline
                src="/voxel.mp4"
              />
              <div className="absolute inset-0 bg-black opacity-40"/>
            </div>
            <div className="relative text-center px-6 md:px-12 z-10 flex flex-col justify-center h-full">
              <h1 className="text-5xl font-extrabold mb-6">Innovate the Future with Us</h1>
              <p className="text-lg mb-8">
              "Innovate, Create, Voxelize"
              </p>
              <Link
                href="/voxelizer"
                className="inline-block bg-gradient-to-r from-orange-400 via-orange-600 to-orange-800 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 transform hover:scale-105"
              >
                Get Started
              </Link>

              <div className="flex justify-center mt-8 space-x-6">
              <Link href="https://www.instagram.com/RefugeStudios_/" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faInstagram} size="2x" className="w-8 h-auto" />
                </Link>
                <Link href="https://www.facebook.com/profile.php?id=61564090825628" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faFacebook} size="2x" className="w-8 h-auto" />
                </Link>
                <Link href="https://www.linkedin.com/company/refuge-studios" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faLinkedin} size="2x" className="w-8 h-auto" />
                </Link>
                <Link href="https://x.com/Refuge_Studios" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faTwitter} size="2x" className="w-8 h-auto" />
                </Link>
                <Link href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faYoutube} size="2x" className="w-8 h-auto" />
                </Link>
              </div>
            </div>
          </main>

          {/* About and Products Sections */}
          <section className="w-full flex flex-col md:flex-row gap-2 mb-2">
            {/* About Section */}
            <section id="about" className="bg-white text-black rounded-2xl shadow-lg p-8 md:w-1/3" >
              <h2 className="text-3xl font-bold mb-4">Online Voxelizer</h2>
              <p className="text-lg mb-6">
                Effortlessly transform your 3D models into detailed voxelized versions. Perfect for simulations, video games, and 3D printing.
                </p>
                <p className="text-lg mb-6">
                Skip the manual voxel modeling process. Simply upload your model, and our tool will handle the conversion for you.
                </p>
                <p className="text-lg mb-6">
                Pricing varies with octree levels; higher levels provide more detail and accuracy.
              </p>

              <Link
                href="/voxelizer"
                className="inline-block bg-gradient-to-r from-orange-400 via-orange-600 to-orange-800 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 transform hover:scale-105"
              >
                Get Started
              </Link>

            </section>



            {/* Showcase Section */}
            <section id="products" className="bg-white text-black rounded-2xl shadow-lg p-8 md:w-2/3">
              <div className="relative flex flex-col items-center justify-center shadow-lg p-8">
                <div className="relative w-full h-80 border-4 border-gray-400 rounded-lg overflow-hidden mb-4">
                  <img
                    src="/images/voxelizer-example-2.png"
                    alt="Voxelized Model Example 1"
                    className={`absolute w-full h-full object-cover transition-transform duration-300`}
                  />
                  <img
                    src="/images/voxelizer-example-1.png"
                    alt="Voxelized Model Example 2"
                    className={`absolute w-full h-full object-cover transition-transform duration-300`}
                    style={{ clipPath: `inset(0 0 0 ${sliderPosition})` }}
                  />
                  <div className="absolute inset-0 flex items-center justify-between px-6">
                    <span className={`text-white font-bold text-lg bg-gray-800 bg-opacity-50 px-3 py-1 rounded ${sliderValue > 0 ? 'opacity-0' : 'opacity-100'}`}>
                      Before
                    </span>
                    <span className={`text-white font-bold text-lg bg-gray-800 bg-opacity-50 px-3 py-1 rounded ${sliderValue < 100 ? 'opacity-0' : 'opacity-100'}`}>
                      After
                    </span>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    className="absolute bottom-4 w-full appearance-none h-3 bg-gray-300 rounded-lg cursor-pointer"
                    style={{
                      background: `linear-gradient(to right, #4F46E5 ${sliderPosition}, #E5E7EB ${sliderPosition})`,
                    }}
                  />
                </div>
                <p className="text-gray-600 text-center mt-4 px-6">
                  Compare the voxelized model with the original. Adjust the slider to see the differences in detail and precision achieved with our advanced voxelizer tool.
                </p>
              </div>
            </section>
          </section>

          {/* Products Section */}
          <section id="products" className="w-full bg-white text-black rounded-2xl shadow-lg p-8 mb-2">
            <h2 className="text-3xl font-bold mb-4">Why Voxelize?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <img src="/voxel-image22.png" alt="Realistic Models" className="w-full h-48 object-cover rounded-lg mb-4" />
                <h3 className="text-2xl font-bold mb-4">Realistic Models</h3>
                <p className="text-lg mb-4">Create highly detailed and realistic 3D models with ease. Perfect for gaming, simulations, and more.</p>
              </div>

              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <img src="/voxel-image95.png" alt="High Precision" className="w-full h-48 object-cover rounded-lg mb-4" />
                <h3 className="text-2xl font-bold mb-4">High Precision</h3>
                <p className="text-lg mb-4">Achieve high precision voxel models that retain the intricate details of your original 3D models.</p>
              </div>
              
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <img src="/voxel-image83.png" alt="Boost Productivity" className="w-full h-48 object-cover rounded-lg mb-4" />
                <h3 className="text-2xl font-bold mb-4">Boost Productivity</h3>
                <p className="text-lg mb-4">Eliminate manually designing voxel art. Simply upload your model and let our tool do the work.</p>
              </div>
            </div>
          </section>

          {/* Contact Section */}
          <section id="contact" className="w-full bg-white text-black rounded-2xl shadow-lg p-8 mb-2">
            <h2 className="text-3xl font-bold mb-4">Get in Touch</h2>
            <p className="text-lg mb-6">
              Interested in our products or services? Feel free to reach out to us for more information or a consultation.
            </p>
            <a
              href="/contact"
              className="bg-blue-500 text-white py-3 px-6 rounded-lg text-lg font-semibold shadow-lg hover:bg-blue-600 transition duration-300"
            >
              Contact Us
            </a>
          </section>
        </main>
        <CookiesPopup/>
        <Footer/>
      </div>
  );
};

export default Home;
